<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Logs</h2>

        <div style="background-color: white; padding:2%;">
            <div class="row mb-3">
                <div class="col-12">
                    <div class="form-inline">
                        <div class="form-group mr-1">
                            <input type="date" v-model="filter.from" class="form-control" placeholder="From Date"/>
                        </div>
                        <div class="form-group mr-1">
                            <input type="date" v-model="filter.to" class="form-control" placeholder="To Date"/>
                        </div>
                        <div class="form-group mr-1 ">
                            <v-select
                                style="width: 300px; font-size: 1rem; background: white; color: #495057 "
                                label="name"
                                v-model="filter.campaign"
                                placeholder="-- Search Campaigns --"
                                :reduce="option => option._id"
                                :filterable="false"
                                :options="campaigns"
                                @search="onSearch"

                            >
                                <template slot="no-options">
                                    Search Campaigns..
                                </template>
                                <template slot="option" slot-scope="option">
                                    <div class="d-center">
                                        {{ option.name }}
                                    </div>
                                </template>
                                <template slot="selected-option" slot-scope="option">
                                    <div class="selected d-center">
                                        {{ option.name }}
                                    </div>
                                </template>
                            </v-select>
                            <!--
                            <select v-model="filter.campaign" class="form-control">
                                <option value="" selected>-- Any Campaign --</option>
                                <option v-for="campaign in campaigns" :key="campaign._id" :value="campaign._id">{{campaign.name}}</option>
                            </select>
                            -->
                        </div>
                        <div class="form-group mr-1">
                            <select v-model="filter.client" class="form-control">
                                <option value="" selected>-- Any Client --</option>
                                <option v-for="client in dropdownClients" :key="client._id" :value="client._id">{{client.name}}</option>
                            </select>
                        </div>
                        <div class="form-group mr-1">
                            <select v-model="filter.product" class="form-control">
                                <option value="" selected>-- Any Product--</option>
                                <option v-for="product in products" :key="product._id" :value="product._id">{{product.name}}</option>
                            </select>
                        </div>
                        <button
                            type="button"
                            class="btn text-white mr-1"
                            @click="search"
                            style="background-color: #383838"
                        >
                            <i class="fa fa-search" title="Search"></i>
                            Search
                        </button>
                        <button
                            type="button"
                            class="btn btn-success mr-1"
                            v-if="showExportButton"
                            @click="exportToExcel"
                        >
                            <i
                                class="fa fa-download"
                                title="Export"
                            >
                            </i>
                            Export
                        </button>
                    </div>
                </div>
            </div>

            <div class="row mb-12" v-if="reportLoading">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <b-spinner style="width: 3rem; height: 3rem;" type="grow" label="Processing......" variant="primary"></b-spinner>
                            <h4>Processing..............</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12">
                    <ejs-grid
                        ref="grid"
                        id="Grid"
                        :dataSource="report"
                        :allowPaging="true"
                        :allowExcelExport='true'
                        :pageSettings='pageSettings'
                    >
                        <e-columns>
                            <e-column headerText='Campaign ID' field="id" :template="lTemplate"></e-column>
                            <e-column field='name' headerText='Campaign Name'></e-column>
                            <e-column field='type' headerText='Type'></e-column>
                            <e-column field='client' headerText='Client'></e-column>
                            <e-column field='total_hits' headerText='Total Logs'></e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import axios from 'axios'
    import swal from 'sweetalert'
    import {GridPlugin, Page, Sort, ExcelExport } from "@syncfusion/ej2-vue-grids";
    import lTemplate from "@/components/lTemplate";
    import VSelect from 'vue-select'
    import Vue from "vue";

    Vue.use(GridPlugin);

    export default {
        name: 'Logs',
        props:['user'],
        components: {VSelect},
        data: function () {
            return {
                campaigns:[],
                report: [],
                items: {total:0, data:[]},
                filter: {
                    from:'',
                    to:'',
                    campaign:'',
                    client: '',
                    product: '605dea639a9d868cfc3e28ba', // Default to AdVerifier
                },
                dropdownClients: [],
                clients: [],
                products: [],
                logDataset: {},
                pageSettings: {pageSize: 100},
                searching: false,
                showExportButton: false,
                reportLoading: false,
                lTemplate: function () {
                    return {
                        template : lTemplate
                    }
                }
            }
        },
        provide: {
            grid: [Page, Sort, ExcelExport ]
        },
        created: function(){
            this.$root.preloader = false;
            this.load();
            this.loadClients();
            this.loadProducts();
            this.GetClientsList();
            window.addEventListener("keydown",this.windowListener);
        },
        destroyed: function() {
            window.removeEventListener("keydown",this.windowListener);
        },
        mounted: function(){

        },
        methods:{
            windowListener: function(e){
                if(e.keyCode === 13 && !this.searching){
                    this.search();
                }
            },
            onSearch(search, loading) {
                if(search.length > 0){
                    loading(true);
                    this.campaignSearch(loading, search, this);
                }
            },
            campaignSearch: function (loading, search, vm) {
                //Load the campaign
                var request = {'apikey': vm.$root.apikey, filters: {}};
                request.query = search;

                axios.get(`${vm.$root.serverUrl}/admin/campaigns/search`, {params: request}).then(function (resp) {
                    vm.campaigns = (resp.data && resp.data.data) ? resp.data.data : null;
                    loading(false);
                });
            },
            search: function(){
                this.searching = true;
                this.reportLoading = true;
                this.report = [];
                this.logDataset = {};

                var request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};
                if(this.filter.from || this.filter.to){
                    request.filters.date = {};
                    if(this.filter.from) request.filters.from= this.filter.from;
                    if(this.filter.to) request.filters.to = this.filter.to;
                    if(this.filter.client) request.filters.client_id = this.filter.client;
                    if(this.filter.product) request.filters.product_id = this.filter.product;
                }

                    if (this.filter.campaign) {
                        request.filters.campaign = this.filter.campaign;
                        this.filter.client = "";
                    }

                return axios.get(`${this.$root.serverUrl}/admin/reports/logs`, {params:request}).then(function(resp){
                    if(resp.data.error){
                        this.reportLoading = false;
                        swal({title:'Oops', text: `${resp.data.error.message}`, icon: 'error'})
                    }else{
                        const result = resp.data.data;
                        if(result.length > 0){
                            const tasks = [];
                            for (let i in result) {
                                tasks.push(this.stage(result[i]));
                            }
                            Promise.all(tasks).then(function () {
                                for(const item in this.logDataset){
                                    this.report.push(this.logDataset[item]);
                                }
                                this.showExportButton = true;
                                this.reportLoading = false;
                            }.bind(this));
                        }else{
                            this.reportLoading = false;
                        }
                    }
                    this.searching = false;
                }.bind(this)).catch(function(err){
                    this.reportLoading = false;
                    swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
                }.bind(this));


            },
            GetClientsList: function(){
                this.cities = [];
                var request = {'apikey': this.$root.apikey};

                return axios.get(`${this.$root.serverUrl}/admin/clients/dropdown-list`, {params:request}).then(function(resp){
                    //Store the stats
                    this.dropdownClients = (resp.data)? resp.data.data : [];
                }.bind(this));
            },
            loadProducts: function(){
                //Load the clients
                var request = {'apikey': this.$root.apikey};
                //this.$root.preloader = true;

                return axios.get(`${this.$root.serverUrl}/admin/products/dropdown-list`, {params:request}).then(function(resp){
                    //Store the stats
                    this.products = (resp.data && resp.data.data)? resp.data.data : [];
                    //this.$root.preloader = false;
                }.bind(this));
            },
            stage: function (dataset) {
                return new Promise(function (resolve) {
                    if (!this.logDataset.hasOwnProperty(dataset.campaign_id)) {
                        this.logDataset[dataset.campaign_id] = {
                            id: dataset.campaign_id,
                            name: dataset.campaign_name,
                            type: dataset.campaign_type,
                            client: dataset.client_name,
                            clientId: dataset.client_id,
                            total_hits: 1,
                            details: []
                        };
                    }else {
                        this.logDataset[dataset.campaign_id].total_hits++;
                    }
                    this.logDataset[dataset.campaign_id].details.push({
                        log_id: dataset._id,
                        log_domain: dataset.domain,
                        log_node: dataset.node,
                        log_date: this.getDate(dataset.date),
                        log_time: this.getTime(dataset.date),
                        log_type: dataset.type,
                        log_message: dataset.message,
                        log_tracker: dataset.tracker,
                        log_proxy_id: dataset.proxy_id,
                        log_proxy_ip: dataset.proxy_ip,
                        log_proxy_port: dataset.proxy_port,
                        log_proxy_provider:dataset.proxy_provider,
                        log_proxy_protocol: dataset.proxy_protocol,
                        log_proxy_country: dataset.proxy_country,
                        log_proxy_state: dataset.proxy_state,
                        log_proxy_city: dataset.proxy_city,
                        log_proxy_weight: dataset.proxy_weight,
                        log_proxy_latitude: dataset.proxy_latitude,
                        log_proxy_longitude: dataset.proxy_longitude,
                        log_proxy_timezone: dataset.proxy_timezone,
                        log_log_campaign_id: dataset.campaign_id,
                        log_campaign_name: dataset.campaign_name,
                        log_campaign_type: dataset.campaign_type,
                        log_client_id: dataset.client_id,
                        log_client_name: dataset.client_name
                    });
                    return resolve();
                }.bind(this));
            },
            load: function(){
                this.filter.from = this.getDateFormatted(new Date());
                this.filter.to = this.getDateFormatted(new Date());
            },
            loadClients: function(){
                //Load the clients
                var request = {'apikey': this.$root.apikey};
                //this.$root.preloader = true;

                return axios.get(`${this.$root.serverUrl}/admin/campaigns/clients`, {params:request}).then(function(resp){
                    //Store the stats
                    this.clients = (resp.data && resp.data.data)? resp.data.data : [];
                    //this.$root.preloader = false;
                }.bind(this));
            },
            exportToExcel:function(){
                let fileName = `Export ${this.filter.from} to ${this.filter.to}`;
                fileName = fileName.replace(/ /g,"_");
                fileName = fileName.replace(/:/g,"_");
                fileName += ".xlsx";
                let excelExportProperties = {
                    fileName,
                    dataSource:this.report
                };
                this.$refs.grid.excelExport(excelExportProperties);
            },
            getDate: function(dateTime){
                let d = new Date(dateTime),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                return [month, day, year].join('/');
            },
            getTime: function(dateTime){
                let d = new Date(dateTime);
                return d.toLocaleTimeString('en-US');
            },
            getDateFormatted: function(d){
                var
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                return [year, month, day].join('-');
            },
            getDateDifference: function(){
                const toDate = new Date(this.filter.to);
                let fromDate = new Date(this.filter.to);
                //fromDate.setDate(toDate.getDate()+1);  //day was off by a day
                fromDate.setMonth(toDate.getMonth()-2); //set two months in the past
                return fromDate;
            },
            fixDates: function(){
                this.filter.from = this.getDateFormatted(this.getDateDifference());
            }
        }
    }
</script>

<style scoped>
.form-control {
    font-size: 0.9rem !important ;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}
</style>
